import { stringify } from 'query-string';
import httpClient from './httpClient';
import { baseApi } from '../common/envUtils';

const statsProvider = {
  getDashboard: async ({ tenantIds }) => {
    const query = {};
    if (tenantIds) {
      query.fk_tenantId = tenantIds;
    }

    const queryString = stringify(query, {
      skipEmptyString: true,
      skipNull: true,
    });

    const url = queryString ? `${baseApi}/stat/dashboard?${queryString}` : `
      ${baseApi}/stat/dashboard`;

    // await httpClient(`${baseApi}/stat/dashboard`, { method: 'POST' });
    return httpClient(url).then(({ json }) => json);
  },
  getMonitoring: ({ startDate, endDate }) => {
    const query = { startDate: startDate || undefined, endDate: endDate || undefined };

    const url = `${baseApi}/stat/monitoring?${stringify(query)}`;
    return httpClient(url).then(({ json }) => json);
  },
};

export default statsProvider;
