import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

/* Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://a6303456936e34ea3a381475e874e205@o4507662681702400.ingest.de.sentry.io/4507662684586064',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
}); */

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
